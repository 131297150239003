<template>
  <div id="preloader" v-if="!loaded">
    <div class="loading-image">
      <img src="/static/preloader.gif" alt="Intera"/>
    </div>
  </div>
  <div v-bind:class="{'sidebar-layout': !noSidebarLayout}" id="main-container">
    <Header :theme="page.slug === 'нашата-работа' ? 'white' : 'regular'"/>
    <div class="d-flex flex-row w-100">
        <div v-if="page && 'components' in page" id="body"
             v-bind:class="{ 'w-100': true, 'index-layout': page.slug === 'home' }">
          <Renderer
              :components="page.components"
              :show-sidebar="!noSidebarLayout"
          />
        </div>
    </div>
    <div class="container-fluid">
      <Footer/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Res from '../helpers/Res';
import PageService from "@/services/PageService";
import Renderer from "@/components/Renderer/Renderer";
import WhiteSidebar from "@/components/Components/WhiteSidebar";

export default {
  name: 'Page',
  components: {
    WhiteSidebar,
    Header,
    Renderer,
  },
  data() {
    return {
      loaded: false,
      isMobile: Res.isMobile(),
      metaTitlePrefix: 'Intera.BG - ',
      page: {},
      components: {
        aboutIntro: {
          component: 'AboutIntro',
        }
      }
    }
  },
  computed: {
    noSidebarLayout() {
      return this.page.hasOwnProperty('layout') && this.page.layout === 'no-sidebar';
    }
  },
  methods: {
  },
  mounted() {
    let vm = this;

    const slug = this.$route.path === '/' ? 'home' : this.$route.path.substr(1);
    let page = PageService.getPageBySlug(slug);
    vm.loaded = false

    page.then((response) => {
      if (typeof response === 'undefined') {
        return Promise.resolve();
      }
      vm.page = (response.data);
      document.title = vm.metaTitlePrefix + vm.page.meta.title
      setTimeout(function () {
        vm.loaded = true;
      }, 3000);
    }).catch((err) => {
      setTimeout(function () {
        vm.loaded = true;
      }, 3000);
    });
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

  #main-container {
    position: relative;
  }

  .sidebar-layout {
    @include for-tablet-landscape-up {
      margin-left: 3.5rem;
    }
  }

  #preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background: #fff;
    @include flex-center-child-items;

    .loading-image {
      width: 139px;
      img {
        width: 139px;
        display: block;
      }
    }
  }
</style>