<template>
  <svg width="252" height="656" viewBox="0 0 252 656" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1390_414)">
      <g filter="url(#filter0_d_1390_414)">
        <path d="M252 335.705C252 485.244 84.8287 656 -64.4585 656C-213.746 656 -304 457.727 -304 308.188C-304 158.648 -289.564 0 -140.277 0C9.01048 0 252 186.165 252 335.705Z" fill="url(#paint0_radial_1390_414)" fill-opacity="0.3" shape-rendering="crispEdges"/>
      </g>
    </g>
    <defs>
      <filter id="filter0_d_1390_414" x="-471" y="-140" width="890" height="990" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="27"/>
        <feGaussianBlur stdDeviation="83.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.921569 0 0 0 0 0.0901961 0 0 0 0 0.309804 0 0 0 0.17 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1390_414"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1390_414" result="shape"/>
      </filter>
      <radialGradient id="paint0_radial_1390_414" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-245.737 146.241) rotate(53.031) scale(732.483 732.142)">
        <stop stop-color="#EB174F"/>
        <stop offset="1" stop-color="#611DF1"/>
      </radialGradient>
      <clipPath id="clip0_1390_414">
        <rect width="252" height="656" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ElipseMobileSvg',
  components: {}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.st0 {
  fill: #220F48;
}

.st1 {
  fill: $color-magenta;
}

</style>