<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>

  <div id="service-carousel" class="page-heading wrapper">

    <div class="d-none d-md-flex flex-column flex-md-row g-0 home-nav pt-1 pb-1" v-if="carousel">
      <button class="button-white" v-for="(category, catIndex) in data.categories">
        <div v-bind:class="{
          'text-underline': true,
          'text-underline-active': carousel.data.currentSlide.value === catIndex}"
           @click="carousel.slideTo(catIndex)"
        >
          {{ category.name }}
        </div>
      </button>
    </div>
    <div class="d-none d-md-block">
      <carousel
          :items-to-show="1"
          :transition="600"
          ref="carousel"
          class="services-carousel"
      >
        <slide v-for="(category, catIndex) in data.categories" :key="catIndex">
          <div>
            <div class="heading-container">
              <div class="red-number">0{{ catIndex+1 }}</div>
              <div class="home-title">
                <div class="home-title-text">
                  {{ category.title }}
                </div>
              </div>
              <div class="line"></div>
            </div>

            <div class="row">
              <div class="service-description col-md-6">
                <div class="m-3 mb-0" v-html="category.body"></div>
              </div>
              <div class="container gallery-main col-md-6">
                <div class="row m-3" v-if="Object.keys(category).includes('singleImage')">
                  <div class="col-12 single-gallery-item"
                       v-bind:style="{'background-image': 'url(/static/' + category.singleImage + ')'}"
                  ></div>
                </div>
                <div class="row m-3" v-if="Object.keys(category).includes('gallery')">
                  <div class="col-6 gallery-item"
                       v-for="(item, imgIndex) in category.gallery"
                       :key="imgIndex"
                       v-bind:style="{'background-image': 'url(/static/' + item.poster + ')'}"
                       @click="openLightbox(item.src, item.type)"
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>

    <div v-if="winW" v-dragscroll class="d-md-none overflow-hidden" v-bind:style="{'width': winW + 'px'}">
      <div class="d-flex flex-nowrap flex-shrink-1 text-no-wrap">
        <button class="button-white" v-for="(category, catIndex) in data.categories">
          <div
              v-bind:class="{
              'text-underline': true,
              'text-underline-active': carousel.data.currentSlide.value === catIndex
              }"

              @click="$refs.carouselMobile.slideTo(catIndex)">{{ category.name }}</div>
        </button>
      </div>
    </div>
    <div class="carousel-mobile d-md-none" v-if="winW" v-bind:style="{'width': winW + 'px'}">

      <carousel
          :items-to-show="1"
          :transition="600"
          ref="carouselMobile"
          class="services-carousel"
      >
        <slide v-for="(category, catIndex) in data.categories" :key="catIndex">
          <div>
            <div class="heading-container">
              <div class="red-number">0{{ catIndex+1 }}</div>
              <div class="home-title">
                <div class="home-title-text">
                  {{ category.title }}
                </div>
              </div>
              <div class="line"></div>
            </div>

            <div class="row">
              <div class="service-description col-md-6">
                <div class="m-3 mb-0" v-html="category.body"></div>
              </div>
              <div class="container gallery-main col-md-6">
                <div class="row m-3" v-if="Object.keys(category).includes('singleImage')">
                  <div class="col-12 single-gallery-item"
                       v-bind:style="{'background-image': 'url(/static/' + category.singleImage + ')'}"
                  ></div>
                </div>
                <div class="row m-3" v-if="Object.keys(category).includes('gallery')">
                  <div class="col-6 gallery-item"
                       v-for="(item, imgIndex) in category.gallery"
                       :key="imgIndex"
                       v-bind:style="{'background-image': 'url(/static/' + item.poster + ')'}"
                       @click="openLightbox(item.src, item.type)"
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>

  </div>
  <InteraLightbox ref="lightbox" :image="image" :video="video"/>
</template>

