<script>
import Button from "@/components/Common/Button";

export default {
  components: {Button}
}
</script>
<style src="./style.scss" lang="scss" scoped/>
<template>
<div class="wrapper">
  <div class="service-footer-title">Искаш безплатна консултация? <span class="red-span">Имаш я!</span></div>
  <div class="service-footer-subtitle">Нашият екип ще ви консултира безплатно и ще разгледаме всички възможни варианти за развитие.</div>
  <Button :theme="'violet'"  @click="$router.push('/контакти')"/>
</div>
</template>
