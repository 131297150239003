<template>

  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 94 23"
       style="enable-background:new 0 0 94 23;" xml:space="preserve">

    <path class="st0" d="M12.2,5.9H6.4v16.6h5.8V5.9z"/>
    <path class="st1" d="M6.4,0H0v5.9h6.4V0z"/>
    <path class="st0" d="M25.5,5.5c1.8,0,3.2,0.6,4.3,1.8c1.1,1.2,1.7,3,1.7,5.3v9.9h-5.4v-9.2c0-0.9-0.2-1.5-0.7-2
	c-0.4-0.4-1-0.7-1.8-0.7c-0.8,0-1.5,0.2-2,0.8C21.2,12,21,12.8,21,13.7v8.8h-5.4V6H21v1.7C22,6.2,23.4,5.5,25.5,5.5z"/>
    <path class="st0" d="M44.5,11.2h-3.4V16c0,0.7,0.3,1.2,0.8,1.4c0.5,0.2,1.4,0.2,2.6,0.2v5c-3.2,0.4-5.5,0.1-6.8-0.9s-2-2.8-2-5.5v-5
	h-2.4V6h2.4V3l5.4-1.7V6h3.4V11.2z"/>
    <path class="st0" d="M51.5,16.3c0.5,1.3,1.8,2,3.6,2c1.2,0,2.2-0.4,3-1.2l3.9,2.8C60.4,22,58,23,55,23c-2.8,0-5-0.8-6.6-2.5
	c-1.6-1.6-2.5-3.7-2.5-6.2c0-2.5,0.8-4.6,2.4-6.3c1.6-1.7,3.7-2.5,6.2-2.5c2.4,0,4.4,0.8,6,2.5c1.6,1.7,2.4,3.7,2.4,6.2
	c0,0.7-0.1,1.4-0.2,2L51.5,16.3L51.5,16.3z M51.4,12.5h6.2c-0.4-1.5-1.4-2.3-3.1-2.3C52.9,10.1,51.9,10.9,51.4,12.5z"/>
    <path class="st0" d="M70.6,9c0.3-1.1,0.9-1.9,1.8-2.5c0.9-0.6,1.9-0.9,3.1-0.9v6.1c-1.2-0.2-2.4,0-3.4,0.7c-1,0.7-1.5,1.8-1.5,3.2
	v6.9h-5.4V6h5.4V9z"/>
    <path class="st0" d="M88.6,6H94v16.6h-5.4V21c-1.1,1.3-2.7,2-4.6,2c-2.2,0-4-0.8-5.4-2.5c-1.5-1.7-2.2-3.8-2.2-6.2s0.7-4.6,2.2-6.2
	C80,6.4,81.8,5.5,84,5.5c2,0,3.5,0.7,4.6,2V6z M82.7,16.9c0.6,0.7,1.4,1,2.5,1s1.8-0.3,2.5-1c0.6-0.7,0.9-1.5,0.9-2.6
	c0-1.1-0.3-2-0.9-2.6c-0.6-0.7-1.4-1-2.5-1s-1.8,0.3-2.5,1c-0.6,0.7-0.9,1.5-0.9,2.6S82.1,16.2,82.7,16.9z"/>
                    </svg>
</template>

<script>
export default {
  name: 'LogoSvg',
  components: {}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.st0 {
  fill: #220F48;
}

.st1 {
  fill: $color-magenta;
}

</style>