<script src="./index.js"/>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="row bg-noise-pattern">
    <div class="bckg-right col-md-6 order-md-2">
      <div class="left-container d-none d-md-block">
        <div
            class="image-container position-absolute"
            v-bind:style="{'background-image': 'url(/static/' + data.image + ')'}"
        >
        </div>
        <div class="circle-right"></div>
      </div>
      <div class="d-md-none mobile-top-image">
        <div class="circle-right">
          <img class="d-md-none w-100" v-bind:src="'/static/' + data.image"/>
        </div>
      </div>
    </div>
    <div class="wrapper col-md-6 md-order-md-1">
      <div class="page-heading">
        <div class="service-title">{{ data.title }}</div>
        <div class="service-title" id="red-span">{{ data.titleHighlight }}</div>
        <p>{{ data.content }}
        </p>
        <div>
          <button class="button-white">Научете повече</button>
        </div>
      </div>
    </div>
  </div>
</template>

