<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="container-fluid wrapper">
    <div class="row">
      <div class="flex-row d-flex  pb-5">
        <div class="carousel-title">Нашата работа</div>
        <div @click="slideNext()" class="align-self-center left-nav ps-5">
          <div class="d-flex justify-content-center circle">
            <svg id="arrow-left" width="16" height="10" viewBox="0 0 16 10" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M15.4611 5.28724C15.7158 5.03255 15.7158 4.61961 15.4611 4.36492L11.3107 0.214514C11.056 -0.0401759 10.643 -0.040176 10.3884 0.214514C10.1337 0.469204 10.1337 0.882137 10.3884 1.13683L14.0776 4.82608L10.3884 8.51533C10.1337 8.77002 10.1337 9.18296 10.3884 9.43765C10.643 9.69234 11.056 9.69234 11.3107 9.43765L15.4611 5.28724ZM14.9999 4.17391L0.652098 4.17391L0.652098 5.47825L14.9999 5.47825L14.9999 4.17391Z"
                  fill="white"/>
            </svg>
          </div>
          <div class="left-arrow"></div>
        </div>
        <div @click="slidePrev()" class="align-self-center right-nav ps-2">
          <div class="d-flex justify-content-center  circle">
            <svg id="arrow-right" width="16" height="10" viewBox="0 0 16 10" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M15.4611 5.28724C15.7158 5.03255 15.7158 4.61961 15.4611 4.36492L11.3107 0.214514C11.056 -0.0401759 10.643 -0.040176 10.3884 0.214514C10.1337 0.469204 10.1337 0.882137 10.3884 1.13683L14.0776 4.82608L10.3884 8.51533C10.1337 8.77002 10.1337 9.18296 10.3884 9.43765C10.643 9.69234 11.056 9.69234 11.3107 9.43765L15.4611 5.28724ZM14.9999 4.17391L0.652098 4.17391L0.652098 5.47825L14.9999 5.47825L14.9999 4.17391Z"
                  fill="white"/>
            </svg>
          </div>


        </div>
      </div>


<!--      <svg class="w-100 position-absolute gradient-carousel-svg" width="587" height="633" viewBox="0 0 587 633"-->
<!--           fill="none"-->
<!--           xmlns="http://www.w3.org/2000/svg">-->
<!--        <g filter="url(#filter0_d_75_243)">-->
<!--          <path-->
<!--              d="M420 293C420 360.931 343.931 438.5 276 438.5C208.069 438.5 167 348.431 167 280.5C167 212.569 173.569 140.5 241.5 140.5C309.431 140.5 420 225.069 420 293Z"-->
<!--              fill="url(#paint0_radial_75_243)" fill-opacity="0.3" shape-rendering="crispEdges"/>-->
<!--        </g>-->
<!--        <defs>-->
<!--          <filter id="filter0_d_75_243" x="0" y="0.5" width="587" height="632" filterUnits="userSpaceOnUse"-->
<!--                  color-interpolation-filters="sRGB">-->
<!--            <feFlood flood-opacity="0" result="BackgroundImageFix"/>-->
<!--            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"-->
<!--                           result="hardAlpha"/>-->
<!--            <feOffset dy="27"/>-->
<!--            <feGaussianBlur stdDeviation="83.5"/>-->
<!--            <feComposite in2="hardAlpha" operator="out"/>-->
<!--            <feColorMatrix type="matrix" values="0 0 0 0 0.921569 0 0 0 0 0.0901961 0 0 0 0 0.309804 0 0 0 0.17 0"/>-->
<!--            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_75_243"/>-->
<!--            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_75_243" result="shape"/>-->
<!--          </filter>-->
<!--          <radialGradient id="paint0_radial_75_243" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"-->
<!--                          gradientTransform="translate(193.512 206.933) rotate(52.9845) scale(332.947 332.947)">-->
<!--            <stop stop-color="$color-magenta"/>-->
<!--            <stop offset="1" stop-color="#611DF1"/>-->
<!--          </radialGradient>-->
<!--        </defs>-->
<!--      </svg>-->


      <div class="flex-row d-flex carousel-items pt-5 pb-5 ">
        <InteraCarouselContainer style="height: 20rem">
          <InteraCarousel
              ref="slider"
              :count="slides.length"
              :show-slides="4"
          >
            <div class="col-sm carousel-item-title" v-for="slide in slides">
              <div class="d-flex justify-content-left">Корпоративни</div>
              <div class="d-flex justify-content-left ">сайтове</div>
            </div>
          </InteraCarousel>
        </InteraCarouselContainer>
      </div>

      <div class="d-flex flex-row">
        <div class="flex-row">
          <Button/>
        </div>
        <div class="flex-row">
          <p class=" carousel-para">Lorem Ipsum е елементарен примерен текст, използван в печатарската и
            типографската
            индустрия.
            Lorem
            Ipsum е
            индустриален стандарт от около 1500 година, когато неизвестен печатар взема няколко печатарски букви и ги
            разбърква, за</p>
        </div>
      </div>
    </div>
  </div>
</template>

