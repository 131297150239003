<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="main-wrapper d-none d-md-flex">
    <div class="p-2">
      <div class="soc-title ">Последвайте ни:</div>
    </div>
    <div class="p-2">
      <div class="d-flex">
        <div class="soc-nav-item foot-item d-flex pe-3">
          <a href="https://www.facebook.com/intera.agency" target="_blank">
            <svg class="me-1 mb-1" width="5" height="9" viewBox="0 0 5 9" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M1.3897 3.23901C1.3897 2.90428 1.39332 2.58716 1.3897 2.26652C1.38607 1.98463 1.41509 1.70627 1.50218 1.43496C1.74528 0.680924 2.26778 0.222864 3.06241 0.0572575C3.24383 0.0184986 3.43251 0.00792796 3.61756 0.00440442C3.99492 -0.00264265 4.37228 0.000880885 4.74964 0.000880885C4.93106 0.000880885 5 0.0643046 5 0.244005C5 0.663306 5 1.08261 5 1.50191C5 1.68513 4.93832 1.74151 4.74964 1.74151C4.44485 1.74503 4.14006 1.74151 3.83527 1.7556C3.50508 1.77322 3.34906 1.94235 3.34543 2.26652C3.3418 2.58716 3.34543 2.90428 3.34543 3.23549C3.38171 3.23549 3.41437 3.23549 3.44702 3.23549C3.8643 3.23549 4.27794 3.23549 4.69521 3.23549C4.873 3.23549 4.94194 3.29891 4.94194 3.47157C4.94194 3.93315 4.94194 4.39121 4.94194 4.85279C4.94194 5.0184 4.87663 5.08182 4.70972 5.08182C4.29245 5.08182 3.87881 5.08182 3.46154 5.08182C3.42888 5.08182 3.39623 5.08182 3.34906 5.08182C3.34906 5.12411 3.34906 5.15934 3.34906 5.19458C3.34906 6.37144 3.34906 7.55183 3.34906 8.72869C3.34906 8.9401 3.28737 9 3.07329 9C2.6016 9 2.13353 9 1.66183 9C1.45138 9 1.39695 8.94715 1.39695 8.73926C1.39695 7.55887 1.39695 6.37496 1.39695 5.19458C1.39695 5.15934 1.39695 5.12411 1.39695 5.0783C1.35704 5.0783 1.32438 5.0783 1.29173 5.0783C0.939768 5.0783 0.58418 5.0783 0.232221 5.0783C0.0689405 5.0783 0 5.01488 0 4.85632C0 4.38769 0 3.91906 0 3.45043C0 3.29891 0.0725689 3.23197 0.228592 3.23197C0.580552 3.23197 0.936139 3.23197 1.2881 3.23197C1.3135 3.23901 1.34615 3.23901 1.3897 3.23901Z"
                  fill="#220F48"/>
            </svg>
            Facebook
          </a>
        </div>
        <div class="g-0 soc-nav-item foot-item d-flex pe-3">
          <a href="https://www.instagram.com/intera.agency/"  target="_blank">
            <svg class="me-1 mb-1" width="10" height="10" viewBox="0 0 10 10" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_36_573)">
                <path
                    d="M9.99608 5.10535C9.99608 5.63743 10 6.17343 9.99608 6.70551C9.99216 7.19846 9.96078 7.68751 9.79216 8.15699C9.45098 9.09987 8.77255 9.66325 7.8 9.87843C7.2549 9.99972 6.69804 9.98798 6.1451 9.99189C5.33725 9.99972 4.52549 9.99972 3.71765 9.99189C3.21176 9.98407 2.70588 9.98798 2.20784 9.87843C1.3451 9.69064 0.701961 9.22507 0.317647 8.41912C0.137255 8.04354 0.0431373 7.63665 0.0352941 7.22194C0.0156863 6.03649 0 4.84322 0 3.65386C0 3.16873 0.0117647 2.6836 0.109804 2.20629C0.345098 1.07953 1.18039 0.277494 2.31765 0.093613C2.92549 -0.00419607 3.53725 -0.000283709 4.15294 -0.000283709C5.05098 -0.000283709 5.9451 0.00754102 6.84314 0.0192781C7.36863 0.0271028 7.8902 0.0818759 8.38039 0.297056C9.21569 0.66873 9.71373 1.31818 9.89412 2.20238C9.96078 2.53102 9.98431 2.87139 9.99216 3.20785C10.0078 3.84166 9.99608 4.47155 9.99608 5.10535ZM9.12941 4.9958C9.12549 4.9958 9.11765 4.9958 9.11372 4.9958C9.11372 4.73368 9.12157 4.47546 9.11372 4.21333C9.09412 3.64995 9.0902 3.08266 9.03137 2.51928C8.95294 1.75637 8.45882 1.22037 7.71373 1.02476C7.31372 0.919122 6.90196 0.919122 6.4902 0.915209C5.65098 0.907385 4.81176 0.907385 3.97647 0.911297C3.46667 0.911297 2.95686 0.89956 2.4549 0.993457C1.76471 1.12256 1.27843 1.50206 1.0549 2.18281C0.933333 2.5584 0.917647 2.94964 0.917647 3.34087C0.909804 4.44807 0.909804 5.55527 0.921569 6.65856C0.92549 6.99111 0.952941 7.33148 1.01961 7.65621C1.14902 8.29001 1.52941 8.72429 2.15294 8.93164C2.48627 9.0451 2.83529 9.06857 3.18431 9.0764C3.70588 9.08814 4.22745 9.09596 4.74902 9.09596C5.62353 9.09596 6.49804 9.12335 7.36863 9.03728C7.76863 8.99815 8.13725 8.87296 8.44706 8.603C8.89019 8.21959 9.05098 7.70707 9.07451 7.15152C9.10588 6.43555 9.11372 5.71568 9.12941 4.9958Z"
                    fill="#220F48"/>
                <path
                    d="M5.0042 7.5665C3.5846 7.56258 2.42773 6.41235 2.42773 4.99608C2.42773 3.5798 3.5846 2.42957 5.0042 2.42957C6.42773 2.42957 7.57675 3.5798 7.57675 4.99608C7.57675 6.41626 6.42381 7.57041 5.0042 7.5665ZM5.00813 6.66274C5.93754 6.66274 6.67479 5.91939 6.67087 4.99216C6.66695 4.07276 5.92577 3.33723 5.00028 3.33723C4.07479 3.33723 3.33754 4.07667 3.33754 4.99999C3.33754 5.92331 4.07871 6.66274 5.00813 6.66274Z"
                    fill="#220F48"/>
                <path
                    d="M8.27811 2.32784C8.27811 2.6643 8.01144 2.92643 7.67811 2.92643C7.34478 2.92643 7.08203 2.65648 7.08203 2.32001C7.08203 1.99138 7.3487 1.72925 7.68203 1.72925C8.01929 1.72925 8.27811 1.99138 8.27811 2.32784Z"
                    fill="#220F48"/>
              </g>
              <defs>
                <clipPath id="clip0_36_573">
                  <rect width="10" height="10" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            Instagram
          </a>
        </div>
        <div class=" oc-nav-item foot-item d-flex">
          <a href="https://www.linkedin.com/company/intera-agency/" target="_blank">
            <svg class="me-1 mb-1" width="10" height="10" viewBox="0 0 10 10" fill="none"
                 xmlns="http://www.w3.org/2000/svg">

              <path
                  d="M9.99471 10C9.24573 10 8.50945 10 7.75623 10C7.75623 9.96002 7.75623 9.92403 7.75623 9.88405C7.75623 8.7365 7.76047 7.58895 7.752 6.4414C7.752 6.1935 7.72238 5.94159 7.6716 5.70169C7.52773 5.04595 7.05803 4.82603 6.41061 4.88201C5.69125 4.94199 5.40774 5.36182 5.30618 5.89361C5.25963 6.14152 5.24271 6.39742 5.24271 6.64532C5.23425 7.7209 5.23848 8.79247 5.23848 9.86805C5.23848 9.90804 5.23848 9.94802 5.23848 9.992C4.4895 9.992 3.74898 9.992 3 9.992C3 7.72489 3 5.45778 3 3.18268C3.71513 3.18268 4.42602 3.18268 5.14961 3.18268C5.14961 3.49056 5.14961 3.79444 5.14961 4.11831C5.17923 4.09032 5.19616 4.08232 5.20462 4.06633C5.577 3.47456 6.14402 3.14669 6.85069 3.04273C7.43887 2.95477 8.02282 2.99875 8.58561 3.21067C9.22457 3.45457 9.58848 3.92639 9.78737 4.53015C9.93547 4.98597 9.99048 5.45379 9.99471 5.9256C10.0032 7.26108 9.99894 8.60055 9.99894 9.93602C9.99894 9.96002 9.99894 9.97601 9.99471 10Z"
                  fill="#220F48"/>
              <path
                  d="M2 10C1.33207 10 0.667932 10 0 10C0 7.66804 0 5.33607 0 3C0.667932 3 1.32827 3 2 3C2 5.33196 2 7.65982 2 10Z"
                  fill="#220F48"/>
              <path
                  d="M0.965821 1.99796C0.386303 1.96226 -0.0336842 1.49494 0.00212872 0.923771C0.0314302 0.388299 0.523044 -0.0335886 1.07326 0.0021096C1.61045 0.0378078 2.0337 0.521356 1.99788 1.06656C1.96533 1.59555 1.48348 2.03366 0.965821 1.99796Z"
                  fill="#220F48"/>
            </svg>
            LinkedIn</a>
        </div>
      </div>
    </div>
    <div class="ms-auto p-2">
      <div class="navbar-text d-flex">
        Създадено с вдъхновение &nbsp;
        <span class="red-span align-self-center ">&#9829;</span>
        &nbsp;<span> от &nbsp;</span>
        <span class="violet-span"><a href="#">Intera Digital</a></span>
      </div>
    </div>
  </div>

  <div class="main-wrapper main-wrapper-mobile d-md-none pt-5 pb-5 text-center">
    <div class="p-2">
      <div class="soc-title ">Последвайте ни:</div>
    </div>
    <div class="p-2">
      <div class="d-flex justify-content-center">
        <div class="soc-nav-item foot-item pe-3">
          <a href="https://www.facebook.com/intera.agency" target="_blank">
            <svg class="me-1 mb-1" width="5" height="9" viewBox="0 0 5 9" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M1.3897 3.23901C1.3897 2.90428 1.39332 2.58716 1.3897 2.26652C1.38607 1.98463 1.41509 1.70627 1.50218 1.43496C1.74528 0.680924 2.26778 0.222864 3.06241 0.0572575C3.24383 0.0184986 3.43251 0.00792796 3.61756 0.00440442C3.99492 -0.00264265 4.37228 0.000880885 4.74964 0.000880885C4.93106 0.000880885 5 0.0643046 5 0.244005C5 0.663306 5 1.08261 5 1.50191C5 1.68513 4.93832 1.74151 4.74964 1.74151C4.44485 1.74503 4.14006 1.74151 3.83527 1.7556C3.50508 1.77322 3.34906 1.94235 3.34543 2.26652C3.3418 2.58716 3.34543 2.90428 3.34543 3.23549C3.38171 3.23549 3.41437 3.23549 3.44702 3.23549C3.8643 3.23549 4.27794 3.23549 4.69521 3.23549C4.873 3.23549 4.94194 3.29891 4.94194 3.47157C4.94194 3.93315 4.94194 4.39121 4.94194 4.85279C4.94194 5.0184 4.87663 5.08182 4.70972 5.08182C4.29245 5.08182 3.87881 5.08182 3.46154 5.08182C3.42888 5.08182 3.39623 5.08182 3.34906 5.08182C3.34906 5.12411 3.34906 5.15934 3.34906 5.19458C3.34906 6.37144 3.34906 7.55183 3.34906 8.72869C3.34906 8.9401 3.28737 9 3.07329 9C2.6016 9 2.13353 9 1.66183 9C1.45138 9 1.39695 8.94715 1.39695 8.73926C1.39695 7.55887 1.39695 6.37496 1.39695 5.19458C1.39695 5.15934 1.39695 5.12411 1.39695 5.0783C1.35704 5.0783 1.32438 5.0783 1.29173 5.0783C0.939768 5.0783 0.58418 5.0783 0.232221 5.0783C0.0689405 5.0783 0 5.01488 0 4.85632C0 4.38769 0 3.91906 0 3.45043C0 3.29891 0.0725689 3.23197 0.228592 3.23197C0.580552 3.23197 0.936139 3.23197 1.2881 3.23197C1.3135 3.23901 1.34615 3.23901 1.3897 3.23901Z"
                  fill="#220F48"/>
            </svg>
            Facebook
          </a>
        </div>
        <div class="g-0 soc-nav-item foot-item pe-3">
          <a href="https://www.instagram.com/intera.agency/"  target="_blank">
            <svg class="me-1 mb-1" width="10" height="10" viewBox="0 0 10 10" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_36_573)">
                <path
                    d="M9.99608 5.10535C9.99608 5.63743 10 6.17343 9.99608 6.70551C9.99216 7.19846 9.96078 7.68751 9.79216 8.15699C9.45098 9.09987 8.77255 9.66325 7.8 9.87843C7.2549 9.99972 6.69804 9.98798 6.1451 9.99189C5.33725 9.99972 4.52549 9.99972 3.71765 9.99189C3.21176 9.98407 2.70588 9.98798 2.20784 9.87843C1.3451 9.69064 0.701961 9.22507 0.317647 8.41912C0.137255 8.04354 0.0431373 7.63665 0.0352941 7.22194C0.0156863 6.03649 0 4.84322 0 3.65386C0 3.16873 0.0117647 2.6836 0.109804 2.20629C0.345098 1.07953 1.18039 0.277494 2.31765 0.093613C2.92549 -0.00419607 3.53725 -0.000283709 4.15294 -0.000283709C5.05098 -0.000283709 5.9451 0.00754102 6.84314 0.0192781C7.36863 0.0271028 7.8902 0.0818759 8.38039 0.297056C9.21569 0.66873 9.71373 1.31818 9.89412 2.20238C9.96078 2.53102 9.98431 2.87139 9.99216 3.20785C10.0078 3.84166 9.99608 4.47155 9.99608 5.10535ZM9.12941 4.9958C9.12549 4.9958 9.11765 4.9958 9.11372 4.9958C9.11372 4.73368 9.12157 4.47546 9.11372 4.21333C9.09412 3.64995 9.0902 3.08266 9.03137 2.51928C8.95294 1.75637 8.45882 1.22037 7.71373 1.02476C7.31372 0.919122 6.90196 0.919122 6.4902 0.915209C5.65098 0.907385 4.81176 0.907385 3.97647 0.911297C3.46667 0.911297 2.95686 0.89956 2.4549 0.993457C1.76471 1.12256 1.27843 1.50206 1.0549 2.18281C0.933333 2.5584 0.917647 2.94964 0.917647 3.34087C0.909804 4.44807 0.909804 5.55527 0.921569 6.65856C0.92549 6.99111 0.952941 7.33148 1.01961 7.65621C1.14902 8.29001 1.52941 8.72429 2.15294 8.93164C2.48627 9.0451 2.83529 9.06857 3.18431 9.0764C3.70588 9.08814 4.22745 9.09596 4.74902 9.09596C5.62353 9.09596 6.49804 9.12335 7.36863 9.03728C7.76863 8.99815 8.13725 8.87296 8.44706 8.603C8.89019 8.21959 9.05098 7.70707 9.07451 7.15152C9.10588 6.43555 9.11372 5.71568 9.12941 4.9958Z"
                    fill="#220F48"/>
                <path
                    d="M5.0042 7.5665C3.5846 7.56258 2.42773 6.41235 2.42773 4.99608C2.42773 3.5798 3.5846 2.42957 5.0042 2.42957C6.42773 2.42957 7.57675 3.5798 7.57675 4.99608C7.57675 6.41626 6.42381 7.57041 5.0042 7.5665ZM5.00813 6.66274C5.93754 6.66274 6.67479 5.91939 6.67087 4.99216C6.66695 4.07276 5.92577 3.33723 5.00028 3.33723C4.07479 3.33723 3.33754 4.07667 3.33754 4.99999C3.33754 5.92331 4.07871 6.66274 5.00813 6.66274Z"
                    fill="#220F48"/>
                <path
                    d="M8.27811 2.32784C8.27811 2.6643 8.01144 2.92643 7.67811 2.92643C7.34478 2.92643 7.08203 2.65648 7.08203 2.32001C7.08203 1.99138 7.3487 1.72925 7.68203 1.72925C8.01929 1.72925 8.27811 1.99138 8.27811 2.32784Z"
                    fill="#220F48"/>
              </g>
              <defs>
                <clipPath id="clip0_36_573">
                  <rect width="10" height="10" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            Instagram
          </a>
        </div>
        <div class="soc-nav-item foot-item">
          <a href="https://www.linkedin.com/company/intera-agency/" target="_blank">
            <svg class="me-1 mb-1" width="10" height="10" viewBox="0 0 10 10" fill="none"
                 xmlns="http://www.w3.org/2000/svg">

              <path
                  d="M9.99471 10C9.24573 10 8.50945 10 7.75623 10C7.75623 9.96002 7.75623 9.92403 7.75623 9.88405C7.75623 8.7365 7.76047 7.58895 7.752 6.4414C7.752 6.1935 7.72238 5.94159 7.6716 5.70169C7.52773 5.04595 7.05803 4.82603 6.41061 4.88201C5.69125 4.94199 5.40774 5.36182 5.30618 5.89361C5.25963 6.14152 5.24271 6.39742 5.24271 6.64532C5.23425 7.7209 5.23848 8.79247 5.23848 9.86805C5.23848 9.90804 5.23848 9.94802 5.23848 9.992C4.4895 9.992 3.74898 9.992 3 9.992C3 7.72489 3 5.45778 3 3.18268C3.71513 3.18268 4.42602 3.18268 5.14961 3.18268C5.14961 3.49056 5.14961 3.79444 5.14961 4.11831C5.17923 4.09032 5.19616 4.08232 5.20462 4.06633C5.577 3.47456 6.14402 3.14669 6.85069 3.04273C7.43887 2.95477 8.02282 2.99875 8.58561 3.21067C9.22457 3.45457 9.58848 3.92639 9.78737 4.53015C9.93547 4.98597 9.99048 5.45379 9.99471 5.9256C10.0032 7.26108 9.99894 8.60055 9.99894 9.93602C9.99894 9.96002 9.99894 9.97601 9.99471 10Z"
                  fill="#220F48"/>
              <path
                  d="M2 10C1.33207 10 0.667932 10 0 10C0 7.66804 0 5.33607 0 3C0.667932 3 1.32827 3 2 3C2 5.33196 2 7.65982 2 10Z"
                  fill="#220F48"/>
              <path
                  d="M0.965821 1.99796C0.386303 1.96226 -0.0336842 1.49494 0.00212872 0.923771C0.0314302 0.388299 0.523044 -0.0335886 1.07326 0.0021096C1.61045 0.0378078 2.0337 0.521356 1.99788 1.06656C1.96533 1.59555 1.48348 2.03366 0.965821 1.99796Z"
                  fill="#220F48"/>
            </svg>
            LinkedIn</a>
        </div>
      </div>
    </div>
    <div class="ms-auto p-2">
      <div class="navbar-text text-center">
        Създадено с вдъхновение &nbsp;
        <span class="red-span align-self-center ">&#9829;</span>
        &nbsp;<span> от &nbsp;</span>
        <span class="violet-span"><a href="https://intera.bg/">Intera Digital</a></span>
      </div>
    </div>
  </div>
</template>

