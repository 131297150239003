<template>
    <div v-bind:class="{'modal-container': true}">
      <div class="modal-box">
        <slot></slot>
      </div>
      <div class="overlay" @click="close()"></div>
    </div>
</template>

<script>
export default {
  name: 'Modal',
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/variables.scss';

.modal-container {
  @include flex-center-child-items;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  .modal-box {
    background: #fff;
    padding: $spacing-lg;
    position: relative;
    z-index: 9998;
    border-radius: 1rem;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $color-purple;
    z-index: 9997;
    opacity: 0.5;
  }

  .close-btn {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    margin: $spacing-sm;
    cursor: pointer;
    font-size: 2.5rem;
  }

}
</style>