<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>

  <div id="service-carousel">
    <div class="wrapper">
      <div class="d-flex">
        <div class="title">Нашата работа</div>
        <div class="arrow arrow-left"></div>
        <div class="arrow arrow-right"></div>
      </div>
      <div class="carousel mt-5 mb-5 d-none d-md-block" v-if="winW" v-bind:style="{'width': winW + 'px'}">
        <div>
          <swiper
              :slides-per-view="4"
              :space-between="100"
              :loop="false"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
          >
            <swiper-slide v-for="(category, catIndex) in data.categories" :key="catIndex">
              <div class="item" @mouseover="changeElipsePosition(catIndex)">
                <div @click="$router.push(category.href)" v-bind:class="{title: true, active: catIndex === lastIndex}">{{ category.name }}</div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="elipse-container" ref="elipse">
          <div class="elipse">
            <ElipseSvg ref="elipseSvg"/>
          </div>
        </div>
      </div>
    </div>
    <div class="d-md-none mobile-menu">
      <div class="wrapper">
        <div class="item-list">
          <div v-for="(category, catIndex) in data.categories" :key="catIndex">
            <div class="item">
              <router-link :to="category.href">{{ category.name }}</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="elipse-mobile">
        <ElipseMobileSvg/>
      </div>
    </div>
  </div>

  <div></div>
</template>

