<template>
  <header class="d-flex pt-2">
    <div class="me-auto logo-svg p-4" id="logo">
      <a href="/">
        <LogoSvg/>
      </a>
    </div>
    <div class="p-2 d-none d-md-block">
      <nav v-bind:class="{
        'top-nav navbar navbar-expand ps-2 pe-2': true,
        'top-nav-light': theme === 'white',
      }">
        <div class="container-fluid">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto pb-0 pl-0 mb-lg-0">
              <li class="nav-item text--black pe-2 ps-2">
                <span>+359 898 57 37 08</span>
              </li>
              <li class="nav-item nav-item-lg pe-2 ps-2">
                <router-link :to="'/нашата-работа'">Нашата работа</router-link>
              </li>
              <li class="nav-item nav-item-lg pe-2 ps-2 text-bold">
                <router-link :to="'/контакти'">Свържете се с нас</router-link>
                <div class="underline">
                  <div class="line underline-pink"></div>
                  <div class="line underline-purple"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div class="ps-4 pt-2 pe-4 d-md-none">
      <div class="h-100 mobile-menu-container">
        <div>
          <button type="button" class=" btn btn-light btn-circle btn-xl top-nav-btn"
                  @click="isMobileMenuOpen = !isMobileMenuOpen">
            <span class="ic-open" v-if="!isMobileMenuOpen">=</span>
            <span class="ic-close" v-else>&#10005</span>
          </button>
        </div>
      </div>
      <nav v-if="isMobileMenuOpen" class=" top-nav-mobile pt-7 ">
        <div class="min-vh-100">
          <ul class="mt-4 mb-0">
            <li class="pt-2 pb-2">
              <router-link :to="'/онлайн-магазин'">
                Онлайн магазин
              </router-link>
            </li>
            <li class="pt-2 pb-2">
              <router-link :to="'/уеб-базиран-софтуер'">
                Уеб базиран софтуер
              </router-link>
            </li>
            <li class="pt-2 pb-2">
              <router-link :to="'/графичен-дизайн'">
                Графичен дизайн
              </router-link>
            </li>
            <li class="pt-2 pb-2">
              <router-link :to="'/видео-и-анимация'">
                Видео и анимация
              </router-link>
            </li>
          </ul>
          <div class="contact-us-btn d-flex pt-4 pb-4 w-100">
            <div class="me-auto">
              <router-link :to="'/контакти'">Свържете се с нас</router-link>
            </div>
            <div class="arrow arrow-left">
              <ArrowIcon/>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="phone">+359 898 57 37 08</div>
          <div class="d-flex justify-content-between d-row social">
            <div class="pe-4">
              <a href="https://www.facebook.com/intera.agency" target="_blank">
                Facebook
              </a>
            </div>
            <div class="pe-4">
              <a href="https://www.instagram.com/intera.agency/"  target="_blank">
                Instagram
              </a>
            </div>
            <div>
              <a href="https://www.linkedin.com/company/intera-agency/" target="_blank">
                Linkedin
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>

import ArrowIcon from "@/components/Common/Icons/ArrowIcon";
import LogoSvg from "@/components/Common/LogoSvg";

export default {
  name: 'Header',
  components: {LogoSvg, ArrowIcon},
  props: {
    theme: {
      type: String,
      default: 'regular'
    },
  },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  mounted() {
    let vm = this;
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .mobile-menu-container {
    @include flex-center-child-items;
  }
}

.top-nav .nav-item-lg a:link,
.top-nav .nav-item-lg a:hover,
.top-nav .nav-item-lg a:active,
.top-nav .nav-item-lg a:visited {
  font-size: .9rem;
  font-family: $font-bold;
}

.underline {
  position: relative;
  height: 0.2rem;
}
.line {
  display: block;
  margin-top: 0.1rem;
  height: 0.2rem;
  border-radius: 3px;
  width: 100%;
  transition: width 0.5s;
  position: absolute;
}

.underline-pink {
  background-color: $color-magenta;
  z-index: 998;
}
.underline-purple {
  background-color: $color-purple-dark;
  z-index: 999;
  width: 0;
}

.top-nav .nav-item:hover .underline-purple {
  width: 100%;
}

.navigation {
  background-color: gray;
}

#logo {
  position: relative;
  z-index: 999;
}

#logo svg {
  max-height: 1.8rem;
  width: 8rem;

  @include for-tablet-landscape-up {
  }
}

#logo img {
  height: 100%;
}

.top-nav a:link,
.top-nav a:active,
.top-nav a:visited {
  color: $color-purple-dark;
  text-decoration: none;
  font-size: 0.8rem;
}

.top-nav a:hover {
  color: $color-magenta;
}

.top-nav-white a:link,
.top-nav-white a:hover,
.top-nav-white a:active,
.top-nav-white a:visited {
  color: white;
  text-decoration: none;

}

.top-nav-white {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.top-nav-contacts {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.top-nav-btn {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background: #fff;
  position: relative;
  z-index: 999;
  line-height: 1;

  .ic-open {
    font-size: 2rem;
  }

  .ic-close {
    font-size: 1.5rem;
  }
}

.top-nav-mobile {
  background: #f0f0f0;
  position: fixed;
  height: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
  padding: 0 1.5rem;
  color: $color-purple-dark;
  font-family: $font-bold;

  .contact-us-btn {
    border-bottom: 5px solid;
    border-color: $color-magenta;
    font-size: 1.2rem;

    .arrow-right {
      justify-content: center;
    }
    .arrow-left {
      transform: rotate(180deg);
    }
  }

  ul {
    list-style-type: none;
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 2rem;
    padding-left: 0;

    a:link, a:hover, a:active, a:visited {
      text-decoration: none;
      font-size: 1.8rem;
      color: $color-purple-dark;
    }
  }

}

.top-nav-light a,
.top-nav-light a:link,
.top-nav-light a:hover,
.top-nav-light a:active,
.top-nav-light a:visited {
    color: #fff!important;
}

.top-nav-mobile a:link,
.top-nav-mobile a:hover,
.top-nav-mobile a:active,
.top-nav-mobile a:visited {
  display: block;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem 1.5rem;
  width: 100%;
}

.phone {
  font-size: 1.5rem;
  margin-bottom: $spacing-sm
}

.social {
  div {
    font-size: 1.1rem;
  }
}

svg {
  .st0 {
    fill: #220F48;
  }

  .st1 {
    fill: $color-magenta;
  }
}
</style>
