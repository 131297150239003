<template>
    <div ref="sliderWrapper" class="wrapper relative d-flex align-items-stretch position-absolute">
      <slot/>
    </div>
</template>

<script>
import {gsap} from 'gsap';

export default {
  name: 'InteraCarousel',
  components: {},
  props: ['count', 'showSlides'],
  data() {
    return {
      winW: null,
      winH: null,
      index: 0,
      lastOffset: 0,
      slides: [],
      isMobile: false,
    }
  },
  mounted() {
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  methods: {
    setIndex(index) {
        this.index = index * (-1);
    },
    getIndex() {
        return this.index;
    },
    getDimensions() {
      this.winW = document.documentElement.clientWidth;
      this.winH = document.documentElement.clientHeight;
      this.isMobile = this.winW < 800;
    },
    getTotalSlides() {
      return Math.ceil(this.$props.count / this.showSlides);
    },
    getProgress() {
      const index = this.index * (-1);
      let progress = (index + 1) / this.getTotalSlides() * 100;

      console.log('--------------------------')
      console.log(index)
      console.log(this.getTotalSlides())
      console.log(progress)
      console.log('--------------------------')

        return progress;
    },
    slidePrev() {
      this.index--;
      this.slide();
    },
    slideNext() {
      this.index++;
      this.slide();
    },
    slide(fast = false) {
      this.check();
      const slider = this.$refs.sliderWrapper;

      let singleSlideW = slider.offsetWidth / this.showSlides;
      if  (this.showSlides === 1) {
         singleSlideW = slider.offsetWidth;
      }

      console.log(slider.offsetWidth)
      console.log(this.$props.count)
      const offsetTo = singleSlideW * this.index * this.$props.showSlides;
      gsap.fromTo(
          slider,
          {marginLeft: this.lastOffset},
          {marginLeft: offsetTo, duration: fast === true ? 0 : 0.9,}
      );
      this.lastOffset = offsetTo;
    },
    check() {
      if (this.getProgress() > 100) {
        this.index++;
      }

      if (this.getProgress() <= 0) {
        this.index--;
      }

    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/variables.scss';

.process-carousel {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  height: 20rem;

  .wrapper {
    position: absolute;
    height: 20rem;
    width: 100%;
  }

}
</style>