<template>
  <button
      @click.prevent=""
      v-bind:class="{
    'button-violet':  theme === 'violet',
    'button-transparent':  theme === 'transparent',
    'button-size-lg':  theme === 'size-lg',
    'd-flex flex-row button': true
  }">
    <div class="me-auto pe-2">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.15183 12.9743C0.683398 13.4428 0.683398 14.2022 1.15183 14.6707C1.62026 15.1391 2.37974 15.1391 2.84817 14.6707L1.15183 12.9743ZM15.2446 1.7774C15.2446 1.11493 14.7076 0.577902 14.0451 0.577902L3.24967 0.577903C2.5872 0.577903 2.05017 1.11493 2.05017 1.7774C2.05017 2.43986 2.58721 2.97689 3.24967 2.97689L12.8456 2.97689L12.8456 12.5728C12.8456 13.2353 13.3827 13.7723 14.0451 13.7723C14.7076 13.7723 15.2446 13.2353 15.2446 12.5728L15.2446 1.7774ZM2.84817 14.6707L14.8933 2.62557L13.1969 0.929226L1.15183 12.9743L2.84817 14.6707Z"
            fill="white"/>
      </svg>
    </div>
    <div>{{ label ?? 'Научете повече' }}</div>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: ['theme', 'size', 'label'],
  components: {}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

button {
  width: auto;
  color: #ffffff;
  padding: 1.2rem 4rem;
  border-radius: 40.6857px;
  border: 0;
  transition: 750ms;
}

.button-size-lg {
  padding: 2rem 5rem;
}

.button:hover {
  background: $color-magenta;

}

.button-violet {
  background: #611DF1;
}

.button-transparent {
  background: transparent !important;
  border: 1px solid #fff;
}

</style>