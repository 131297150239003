<script src="./index.js"/>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="container-fluid">
      <div class="row bckg-grid relative d-flex align-items-stretch">
        <div class="col-sm bckg left-info-container">
          <div>
            <h1 class="work-title">Нашата <span class="red-span">работа</span></h1>
            <div class="work-subtitle pt-3 pb-3">Опитът ни в различни сфери на дизайна и уменията ни за работа с най-новите инструменти и технологии, гарантират отличен резултат.
            </div>
            <div class="line-divider pt-3 "></div>
            <div class="row d-flex align-items-stretch relative pt-4 pb-5">

              <div class="col-md-12 text-para">
                Не се колебайте да се свържете с нас. Ние приемаме всяко уеб дизайн предизвикателство. Дизайнът, който създаваме е уникален и персонализиран спрямо вашия бранд. Залагаме на индивидуалността като фундаментален компонент от добрата и работеща визия. Нашата страст е да виждаме нашите клиенти удовлетворени не само от добре свършената работа, но и от лесния и прозрачен процес на работа.

              </div>
<!--              <div class="col-md-6 text-para">Lorem Ipsum е елементарен примерен текст, използван в печатарската-->
<!--                и-->
<!--                типографската индустрия.-->
<!--                Lorem Ipsum е индустриален стандарт от около 1500 година, когато-->
<!--              </div>-->

            </div>
            <div class="circle-text d-none d-md-flex align-items-baseline">
              <svg width="523" height="306"
                   viewBox="0 0 523 306" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_9_487)">
                  <path
                      d="M339.816 5.91596C345.197 7.48404 349.233 10.5489 351.924 15.1106C354.615 19.6723 355.11 24.733 353.623 30.2213C352.066 35.7096 348.95 39.7011 344.277 42.1245C339.603 44.6191 334.576 45.0468 329.194 43.55L322.113 41.483L317.581 57.5915L303.915 53.6713L319.068 0L339.816 5.91596ZM332.876 30.5064C334.434 30.934 335.85 30.7915 337.196 30.0074C338.541 29.2234 339.461 28.0117 339.957 26.3011C340.453 24.5904 340.311 23.0936 339.532 21.7394C338.753 20.3851 337.62 19.5298 336.133 19.0309L329.052 16.9638L325.795 28.3681L332.876 30.5064Z"
                      fill="white"/>
                  <path
                      d="M387.4 43.0511L399.083 49.1096L380.956 84.6053L369.272 78.5468L370.972 75.2681C367.148 76.8362 363.041 76.5511 358.792 74.3415C354.119 71.9181 351.074 68.0691 349.729 62.866C348.383 57.6628 349.091 52.3883 351.782 47.0426C354.544 41.6968 358.368 38.0617 363.395 36.1372C368.423 34.2128 373.238 34.4266 377.911 36.9213C382.16 39.1308 384.78 42.267 385.771 46.4011L387.4 43.0511ZM362.687 59.7298C363.324 61.8681 364.74 63.5074 366.936 64.6479C369.131 65.7883 371.326 66.0021 373.379 65.2894C375.503 64.5766 377.132 63.0798 378.265 60.7277C379.469 58.3755 379.752 56.166 379.115 54.0277C378.478 51.8894 377.061 50.25 374.866 49.1096C372.671 47.9691 370.476 47.7553 368.423 48.4681C366.298 49.1808 364.67 50.6777 363.537 53.0298C362.333 55.3819 362.05 57.6628 362.687 59.7298Z"
                      fill="white"/>
                  <path
                      d="M399.791 100.856C394.693 96.7223 391.861 91.8755 391.294 86.1734C390.728 80.5425 392.498 75.1255 396.605 69.9936C400.783 64.7904 404.889 61.2979 408.996 59.5872C413.103 57.8766 418.131 57.6628 424.079 59.017C430.523 60.4425 434.63 61.1553 436.471 61.2266C438.453 61.2266 440.365 60.6564 442.277 59.6585L446.809 71.2766C444.33 73.1298 441.711 73.9851 438.878 73.9851C436.046 73.9851 432.434 73.2723 427.903 71.9181C424.291 70.7777 421.601 70.2074 419.76 70.1362C417.918 70.0649 416.29 70.35 414.874 71.134C418.343 70.9915 421.742 72.2745 424.999 74.9117C428.398 77.6915 430.523 81.3979 431.231 86.0308C431.939 90.7351 430.664 95.083 427.407 99.217C424.008 103.422 419.83 105.774 414.732 106.202C409.634 106.559 404.677 104.777 399.791 100.856ZM403.827 86.7436C404.111 88.7394 405.102 90.45 406.801 91.8043C408.501 93.1585 410.342 93.7287 412.254 93.5862C414.166 93.3723 415.794 92.517 417.069 90.9489C418.343 89.3096 418.839 87.5277 418.556 85.5319C418.273 83.5362 417.281 81.8968 415.511 80.4713C413.953 79.1883 412.183 78.6181 410.271 78.6181C408.359 78.6894 406.731 79.5447 405.385 81.184C404.04 82.8947 403.544 84.7479 403.827 86.7436Z"
                      fill="white"/>
                  <path
                      d="M429.673 130.151C425.849 125.661 424.221 120.457 424.645 114.684C425.07 108.911 427.549 104.064 432.01 100.286C436.471 96.4372 441.569 94.7979 447.375 95.3681C453.111 95.9383 457.926 98.433 461.679 102.923C465.503 107.485 467.202 112.617 466.777 118.39C466.352 124.164 463.874 128.939 459.413 132.788C454.952 136.637 449.854 138.277 444.118 137.706C438.241 137.207 433.497 134.641 429.673 130.151ZM439.303 121.883C440.79 123.665 442.631 124.663 444.755 124.805C446.88 124.948 448.933 124.235 450.774 122.667C452.615 121.099 453.677 119.246 453.819 117.036C454.031 114.898 453.394 112.902 451.836 111.12C450.349 109.338 448.579 108.412 446.384 108.269C444.26 108.127 442.206 108.839 440.365 110.407C438.524 111.976 437.462 113.829 437.32 116.038C437.25 118.177 437.887 120.101 439.303 121.883Z"
                      fill="white"/>
                  <path
                      d="M495.88 156.167L484.975 162.582L479.24 152.746L455.943 166.502L449.287 155.098L472.583 141.341L466.777 131.363L477.682 124.948L495.88 156.167Z"
                      fill="white"/>
                  <path
                      d="M509.688 191.164L513.228 200.786L475.982 214.543L471.521 202.426L488.941 195.939L466.211 187.885L462.67 178.263L499.916 164.506L504.377 176.623L486.958 183.11L509.688 191.164Z"
                      fill="white"/>
                  <path
                      d="M523 257.166L483.772 262.868L481.93 250.252L500.837 247.472L486.604 240.915L486.392 239.489L498.146 229.226L479.311 232.005L477.469 219.318L516.698 213.616L518.539 226.303L505.723 237.422L521.159 244.55L523 257.166Z"
                      fill="white"/>
                  <path
                      d="M479.664 304.208C480.443 298.221 483.063 293.446 487.595 289.953C492.127 286.461 497.296 285.106 503.173 285.89C509.05 286.674 513.724 289.312 517.194 293.802C520.663 298.364 522.009 303.638 521.23 309.626C520.734 313.546 519.389 316.967 517.123 319.889C514.857 322.812 512.095 325.021 508.767 326.304L503.74 314.187C506.501 313.118 508.059 310.98 508.484 307.772C508.767 305.563 508.272 303.638 506.926 301.928C505.652 300.288 503.811 299.29 501.403 298.934C499.066 298.649 497.013 299.148 495.313 300.431C493.614 301.714 492.623 303.496 492.339 305.705C491.915 308.984 492.906 311.479 495.384 313.118L487.524 323.524C484.621 321.386 482.426 318.606 481.01 315.114C479.664 311.764 479.169 308.057 479.664 304.208Z"
                      fill="white"/>
                  <path
                      d="M0.849731 302.427C0.212444 297.152 1.62864 292.448 5.16912 288.385C8.7096 284.322 13.383 281.899 19.331 281.186C25.2791 280.473 30.4482 281.614 34.8384 284.679C39.2286 287.744 41.7777 291.949 42.415 297.223C42.9815 301.999 41.9193 305.99 39.0869 309.055L58.418 306.632L60.0466 319.747L4.95669 326.661L3.32807 313.474L6.93936 313.047C3.46969 310.766 1.48702 307.202 0.849731 302.427ZM28.9612 307.986C30.3774 306.276 30.873 304.137 30.5898 301.643C30.3065 299.148 29.2444 297.223 27.4742 295.94C25.7039 294.586 23.5088 294.087 20.9597 294.444C18.4105 294.8 16.4279 295.798 15.0117 297.509C13.5955 299.219 13.0998 301.357 13.383 303.852C13.6663 306.347 14.7284 308.271 16.4987 309.554C18.2689 310.909 20.464 311.407 23.0131 311.051C25.6331 310.766 27.6158 309.697 28.9612 307.986Z"
                      fill="white"/>
                  <path
                      d="M25.2082 262.654C28.5363 261.585 30.3773 258.734 30.6606 254.101C30.873 251.25 30.0941 248.755 28.3947 246.617L35.7589 237.565C40.3615 241.841 42.415 247.757 41.8485 255.241C41.3528 262.013 39.0161 267.216 34.7675 270.994C30.519 274.771 25.4207 276.411 19.4018 275.983C13.4538 275.555 8.63879 273.203 4.88587 268.927C1.20377 264.65 -0.42485 259.447 8.24779e-06 253.246C0.424866 247.33 2.76158 242.554 7.01016 238.99C11.2587 235.427 16.357 233.858 22.305 234.286C24.0753 234.429 25.6331 234.714 27.1909 235.213L25.2082 262.654ZM16.1446 262.084L17.2776 246.831C13.5246 247.615 11.4712 249.967 11.1879 253.887C10.8339 257.95 12.5333 260.73 16.1446 262.084Z"
                      fill="white"/>
                  <path
                      d="M12.1793 194.941L15.7198 182.183L53.8862 193.017L50.3457 205.776L46.8052 204.778C49.1419 208.27 49.6376 212.333 48.363 216.966C46.9468 222.098 43.8312 225.804 39.0161 228.228C34.2011 230.58 28.9612 230.936 23.1548 229.368C17.4192 227.729 13.0998 224.664 10.1966 220.102C7.29342 215.54 6.58532 210.694 8.00152 205.633C9.27609 201 11.8252 197.793 15.649 196.011L12.1793 194.941ZM33.4222 215.897C35.334 214.828 36.6794 213.117 37.3167 210.694C38.0248 208.27 37.7416 206.132 36.6794 204.207C35.5465 202.283 33.7762 201 31.2979 200.287C28.8195 199.574 26.5536 199.788 24.6418 200.786C22.7299 201.855 21.3845 203.566 20.7473 205.989C20.0392 208.413 20.3224 210.551 21.3845 212.476C22.5175 214.4 24.2877 215.683 26.7661 216.396C29.2444 217.18 31.4395 216.966 33.4222 215.897Z"
                      fill="white"/>
                  <path
                      d="M35.2632 140.914L46.5928 146.545L41.5653 156.809L65.7822 168.854L59.9758 180.757L35.7589 168.712L30.6606 179.118L19.3311 173.487L35.2632 140.914Z"
                      fill="white"/>
                  <path
                      d="M56.7185 110.122L62.7373 101.926L94.6725 125.661L87.0251 136.067L72.0842 124.948L77.8906 148.469L71.8718 156.666L39.9366 132.931L47.5841 122.596L62.5249 133.715L56.7185 110.122Z"
                      fill="white"/>
                  <path
                      d="M106.781 86.0309C110.675 84.7479 114.004 85.6745 116.836 88.6681C119.243 91.234 120.235 94.0851 119.881 97.2213C119.527 100.357 117.756 103.494 114.428 106.559L98.213 121.74L71.1637 92.517L86.3878 78.2617C89.645 75.1968 92.8314 73.6287 95.9471 73.4862C99.0627 73.3436 101.824 74.6266 104.232 77.1926C106.639 79.7585 107.489 82.8234 106.781 86.0309ZM91.6985 86.9574L87.1667 91.234L90.7072 95.083L95.239 90.8064C96.7968 89.3809 97.0092 88.0266 95.8055 86.7436C94.6017 85.4606 93.2563 85.5319 91.6985 86.9574ZM105.931 100.856C107.418 99.4309 107.56 98.0766 106.285 96.7223C105.082 95.4394 103.736 95.5106 102.249 96.9362L96.9384 101.854L100.621 105.846L105.931 100.856Z"
                      fill="white"/>
                  <path
                      d="M126.537 48.3255L137.654 41.1979L158.897 74.9117L147.78 82.0394L140.486 70.4213L130.644 76.6936L137.937 88.3117L126.82 95.4394L105.577 61.7255L116.694 54.5979L123.988 66.216L133.83 59.9436L126.537 48.3255Z"
                      fill="white"/>
                  <path
                      d="M181.768 66.5723C176.316 68.7106 170.934 68.6394 165.624 66.3585C160.313 64.0777 156.631 60.1574 154.507 54.6691C152.382 49.1809 152.524 43.7638 154.861 38.4894C157.268 33.2149 161.163 29.5085 166.615 27.3702C172.138 25.2319 177.52 25.3032 182.83 27.584C188.141 29.8649 191.823 33.7851 193.948 39.2734C196.072 44.7617 195.93 50.1787 193.594 55.4532C191.257 60.7277 187.292 64.434 181.768 66.5723ZM177.166 54.6691C179.361 53.8138 180.848 52.3883 181.698 50.3926C182.547 48.3968 182.547 46.2585 181.627 43.9777C180.777 41.6968 179.361 40.1287 177.378 39.2021C175.395 38.3468 173.342 38.2755 171.218 39.1309C169.093 39.9862 167.606 41.3404 166.757 43.3362C165.907 45.3319 165.907 47.4702 166.828 49.7511C167.677 52.0319 169.093 53.6 171.076 54.5266C172.988 55.4532 175.041 55.5245 177.166 54.6691Z"
                      fill="white"/>
                  <path
                      d="M223.546 54.883C217.598 55.9521 212.287 54.883 207.614 51.6755C202.94 48.3968 200.037 43.9064 198.975 37.9904C197.913 32.1457 198.975 26.8713 202.232 22.167C205.49 17.4628 210.021 14.5404 215.969 13.4713C219.793 12.7585 223.475 13.0436 226.945 14.2553C230.415 15.5383 233.318 17.534 235.584 20.3138L225.67 28.7957C223.829 26.5149 221.28 25.6596 218.165 26.2298C215.969 26.6574 214.27 27.7266 213.137 29.5085C211.933 31.2904 211.579 33.3574 212.004 35.7096C212.429 38.0617 213.491 39.8436 215.261 41.0553C216.961 42.267 218.943 42.6947 221.138 42.267C224.396 41.6968 226.378 39.9149 227.228 37.0638L239.478 41.483C238.345 44.9755 236.363 47.8979 233.53 50.3213C230.698 52.6021 227.37 54.1702 223.546 54.883Z"
                      fill="white"/>
                  <path
                      d="M275.52 10.3351L275.733 23.0223L264.403 23.2362L264.828 50.4638L251.658 50.6777L251.233 23.45L239.762 23.6638L239.549 10.9766L275.52 10.3351Z"
                      fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_9_487">
                    <rect width="523" height="536" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div class="col-lg bckg bckg-right">
          <ul class="work-nav">
            <li class="pt-3 pb-3 work-item work-item">
              <router-link :to="'/онлайн-магазин'">
                <div>Онлайн</div>
                <div>магазин</div>
              </router-link>
            </li>
            <li class="pt-3 pb-3 work-item work-item">
              <router-link :to="'/уеб-базиран-софтуер'">
                <div>Уеб базиран</div>
                <div>софтуер</div>
              </router-link>
            </li>
            <li class="pt-3 pb-3 work-item work-item">
              <router-link :to="'/графичен-дизайн'">
                <div>Графичен</div>
                <div>дизайн</div>
              </router-link>
            </li>
            <li class="pt-3 pb-3 work-item">
              <router-link :to="'/видео-и-анимация'">
                <div>Видео</div>
                <div>и анимация</div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

