import axios from 'axios';

export default class Request {

    static getBaseUrl() {
        const host = window.location.hostname;

        if (!window.location.host.includes('localhost') && !window.location.host.includes('192.168')) {
            return 'https://' + host;
        }
        return 'http://' + host + ':8080';
    }

    static request(method, uri, params = null) {

        const config = {
            method: method,
            url: this.getBaseUrl() + uri,
            headers: {},
            params: {},
        };

        if (params) {
            config.data = params;
        }

        let request = axios.request(config);
        return request.then((result) => {
            return new Promise((resolve, reject) => {
                resolve(request);
            });
        }).catch((err)=>{
            return new Promise((resolve, reject) => {
                reject(err);
            });
        });
    }
}