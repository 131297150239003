<template>
    <div class="position-relative overflow-hidden w-100">
      <slot/>
    </div>
</template>

<script>
export default {
  name: 'InteraCarouselContainer',
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/variables.scss';

</style>