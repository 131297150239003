import InteraLightbox from "@/components/Common/InteraLightbox/InteraLightbox";

export default {
    name: 'ServicePageDetailsGallery',
    components: {
        InteraLightbox
    },
    props: ['data'],
    data() {
        return {
            index: null,
            image: null
        }
    },
    mounted() {
    },
    methods: {
        openLightbox(image) {
            let lightbox = this.$refs.lightbox;
            this.image = image;
            lightbox.open();
        }
    }
}