<script>
import Button from "@/components/Common/Button";

export default {
  components: {Button}
}
</script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="page-heading wrapper">
    <div class="flex-row home-title">Заедно изпълняваме</div>
    <div class="flex-row home-title">вашите бизнес цели</div>
    <div class="flex-row home-subtitle pt-3 pb-4">Заедно реализираме и най-налудничавите проекти. Създаваме работещи продукти, вдъхновени от вашите идеи.
    </div>
    <div class="flex-row home-nav-title pt-4 pb-4">Как можем да ви бъдем полезни?</div>
    <div class="flex-column flex-md-row d-flex home-nav pt-4 pb-4">
      <router-link :to="'/онлайн-магазин#разработка на онлайн магазин'" class="button-white d-flex flex-row mb-3 mb-md-0">
        <div>Разработка на онлайн магазин</div>
      </router-link>
      <router-link :to="'/корпоративен-сайт'" class="button-white d-flex flex-row mb-3 mb-md-0">
        <div>Разработка на корпоративен сайт</div>
      </router-link>
      <router-link :to="'/графичен-дизайн#лого дизайн'" class="button-white d-flex flex-row mb-3 mb-md-0">
        <div>Изработка на лого</div>
      </router-link>
      <router-link :to="'/уеб-базиран-софтуер'" class="button-white d-flex flex-row mb-3 mb-md-0">
        <div>Уеб базиран софтуер</div>
      </router-link>
      <router-link :to="'/графичен-дизайн#опаковки'" class="button-white d-flex flex-row mb-3 mb-md-0">
        <div>Създаване на опаковки и принт дизайн</div>
      </router-link>
      <router-link :to="'/видео-и-анимация'" class="button-white d-flex flex-row mb-3 mb-md-0">
        <div>Видео и анимация</div>
      </router-link>
    </div>
    <p class="home-nav-para pb-3">Опитът ни в различни сфери на дизайна и уменията ни за работа с най-новите инструменти и технологии, гарантират отличен резултат.
    </p>
    <div class="flex-row">
      <router-link :to="'/нашата-работа'">
        <Button :theme="'violet'"/>
      </router-link>
    </div>
    <div class="circle-wrapper d-none d-md-block">
      <div class="circle"></div>
    </div>
  </div>
</template>

