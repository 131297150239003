<template>
  <div>
    <div v-for="component in components">
      <component v-if="component.id" :is="component.id" v-bind="component"></component>
    </div>
    <WhiteSidebar v-if="!isMobile && showSidebar"/>
  </div>
</template>

<script>
import OurWorkMain from "@/components/Components/OurWorkMain/OurWorkMain";
import ContactsMain from "@/components/Components/ContactsMain/ContactsMain"
import WhiteSidebar from "@/components/Components/WhiteSidebar/WhiteSidebar"
import HomeHeader from "@/components/Components/HomeHeader/HomeHeader"
import HomeCarousel from "@/components/Components/HomeCarousel/HomeCarousel"
import HomeProcessReview from "@/components/Components/HomeProcessReview/HomeProcessReview"
import HomeFooter from "@/components/Components/HomeFooter/HomeFooter"
import SimpleFooter from "@/components/Components/SimpleFooter/SimpleFooter"
import ServicePageHeader from "@/components/Components/ServicePageHeader/ServicePageHeader"
import ServicePageCarousel from "@/components/Components/ServicePageCarousel/ServicePageCarousel"
import ServicePageDetailsGallery from "@/components/Components/ServicePageDetailsGallery/ServicePageDetailsGallery"
import ServicePageFooter from "@/components/Components/ServicePageFooter/ServicePageFooter"
import HomeServiceCarousel from "@/components/Components/HomeServiceCarousel/HomeServiceCarousel"
import Res from "@/helpers/Res";

export default {
  name: 'Renderer',
  props: ['components', 'showSidebar'],
  components: {
    OurWorkMain,
    ContactsMain,
    WhiteSidebar,
    HomeHeader,
    HomeCarousel,
    HomeProcessReview,
    HomeFooter,
    ServicePageHeader,
    ServicePageCarousel,
    ServicePageDetailsGallery,
    ServicePageFooter,
    SimpleFooter,
    HomeServiceCarousel
  },
  data() {
    return {
      isMobile: Res.isMobile(),
    }
  },
  mounted() {
    let vm = this;
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
