<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="position-relative d-none d-md-block">
    <div v-bind:class="{'overlay': isOpen, 'pointer': !isOpen}" @click="closeSidebar()"></div>
    <div v-bind:class="{'col-sm  white-sidebar': true, 'is-open d-block': isOpen}" @click="openSidebar()">
      <div class="inquiry-text">
        <div v-if="!isOpen">Изпратете запитване</div>
      </div>
      <div class="wrapper">
        <div class="sidebar-title">
          <div>Заедно ще развием</div>
          <div>вашите идеи</div>
        </div>
        <div class="contact-form">
          <form action="">
            <div>
              <div class="contact-form-heading">За проекта</div>
              <div class="form-group pt-2 pb-5">
                <label>Опишете ни как можем да съдействаме или какъв е
                  проблемът, който
                  трябва
                  да бъде решен</label>
                <textarea
                    v-bind:class="{'form-control text-box': true, 'input-error': formSent && !message}"
                    v-model="message"
                    style="min-height: 10rem"
                    placeholder="Вашето съобщение">
                </textarea>
              </div>
            </div>
            <div>
              <div class="contact-form-heading">За връзка с вас</div>
              <div class="form-group  pt-2  pb-2 ">
                <label for="inputEmail">Вашият email</label>
                <input type="email"
                       v-bind:class="{'form-control text-box': true, 'input-error': formSent && !email}"
                       v-model="email"
                       placeholder="Email"
                >
              </div>
              <div class="form-group pb-2">
                <label for="inputPhone">Вашият телефон</label>
                <input type="text"
                       v-bind:class="{'form-control text-box': true, 'input-error': formSent && !phone}"
                       v-model="phone"
                       placeholder="Телефон"
                >
              </div>
            </div>
            <div class="flex-row d-flex check pt-3 pb-3">
              <div class=" align-self-start">
                <input
                    type="checkbox"
                    v-model="privacy"
                    v-bind:class="{'input-error': formSent && !privacy}"
                >
              </div>
              <div class="ps-1 align-self-start justify-center">Съгласявам се с
                <a href="#">Политиката за поверителност</a> на сайта
              </div>
            </div>
            <Button :theme="'violet'" :label="'Изпратете сега'" @click="send()"/>
          </form>
        </div>
      </div>
    </div>
  </div>
  <Modal v-if="showCaptcha">
    <div class="captcha-modal">
      <div><RobotIcon/></div>
      <div class="mt-4">Моля потвърдеете че не сте робот:</div>
      <div class="mt-4">
        <vue-recaptcha
            ref="captcha"
            type="checkbox"
            sitekey="6LfMU0siAAAAAJ5WBJWrKyahVKsTMTrAFgiB3wyw"
            @verify="verifyMethod"
            @expired="expiredMethod"
            @error="errorMethod"
        >
        </vue-recaptcha>
      </div>
    </div>
  </Modal>
  <Modal v-if="showSuccess">
    <div class="success-modal">
      <div><ThumbUpIcon/></div>
      <div class="mt-4">Успешно изпратихте запитване</div>
      <div class="mt-4">
        <Button :theme="'violet'" :label="'Супер, ОК →'" @click="showSuccess = false; showCaptcha = false; isOpen = false"/>
      </div>
    </div>
  </Modal>
</template>

