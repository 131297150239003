<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>

  <div class="position-relative">
    <div class="pattern-background">
      <div class="container-fluid g-0 row wrapper">
        <div class="col-sm position-relative my-atropos phone-in-hand-wrapper">
          <div class="phone-in-hand position-absolute"></div>
        </div>
        <div class="col-sm right-content">
          <div>
            <div class="flex-row heading">Процесът на работа</div>
            <div class="flex-row headingTitle">
              <div class="">Ние откриваме и решаваме</div>
              <div class="">проблемите на</div>
              <div class="">вашите потребители</div>
            </div>
            <div class="row heading-paragraphs">
              <p class="col-12">
                Познаваме множество похвати за анализ на потребителското поведение на потребителите и правилното адаптиране на дизайна спрямо нуждните им. Основен акцент в процеса на работата ни е да се “поставяме в обувките” на крайните потребители, за да разберем как на тях би им било най-удобно да работят със системата ви. UX дизайна е водещ в разбратотката на един работещ софтуерен продукт.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

