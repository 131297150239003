
import Modal from "@/components/Common/Modal/Modal";
import RobotIcon from "@/components/Common/Icons/RobotIcon";
import ThumbUpIcon from "@/components/Common/Icons/ThumbUpIcon";
import Button from "@/components/Common/Button";
import {VueRecaptcha} from "vue-recaptcha";
import EmailService from "@/services/EmailService";

export default {
    name: 'ContactsMain',
    components: {
        VueRecaptcha,
        RobotIcon,
        Modal,
        ThumbUpIcon,
        Button
    },
    data() {
        return {
            formSent: false,
            captchaRef: null,
            showSuccess: false,
            showCaptcha: false,
            email: null,
            phone: null,
            message: null,
        }
    },
    methods: {
        send() {
            this.formSent = true;
            if (this.email && this.phone && this.message) {
                this.showCaptcha = true;
            }
        },
        success() {
            this.showSuccess = true;
            this.showCaptcha = false;
            this.email = null;
            this.phone = null;
            this.message = null;
            this.formSent = false;
        },
        verifyMethod(captcha) {
            let vm = this;
            const mail = EmailService.send(this.email, this.phone, this.message, captcha);

            mail.then((response) => {
                if (typeof response === 'undefined') {
                    return Promise.resolve();
                }
                vm.success();
            }).catch((err) => {
                vm.success();
            });
        },
        expiredMethod() {
            this.captchaRef.reset();
        },
        errorMethod() {
        },
    }
}