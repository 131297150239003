import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper.min.css';
import Button from '@/components/Common/Button'
import ElipseSvg from "@/components/Common/ElipseSvg";
import ElipseMobileSvg from "@/components/Common/ElipseMobileSvg";
import {gsap} from 'gsap';

export default {
    name: 'HomeServiceCarousel',
    components: {
        Swiper,
        SwiperSlide,
        Button,
        ElipseSvg,
        ElipseMobileSvg
    },
    props: ['data'],
    data() {
        return {
            winW: null,
            carousel: null,
            selectedTab: 0,
            image: null,
            elipsePosition: 1,
            lastIndex: 1
        }
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
        };
    },
    mounted() {
        this.carousel = this.$refs.carousel;
        window.addEventListener('resize', this.setDimensions);
        this.setDimensions();
    },
    unmounted() {
        window.removeEventListener('resize', this.setDimensions);
    },
    methods: {
        setDimensions() {
            this.winW = document.getElementById("service-carousel").clientWidth - 50;
        },
        changeElipsePosition(index) {
            if (this.lastIndex === index) {
                return;
            }

            const elipse = this.$refs.elipse;
            const elipseSvg = this.$refs.elipseSvg;

            if (this.lastIndex < index) {
                elipseSvg.animateRight();
            } else {
                elipseSvg.animateLeft();
            }

            const timeline = gsap.timeline()
                .to(elipse, .8, {left: (index * 25) + '%' });

            timeline.eventCallback("onComplete", function() {
                elipseSvg.animate();
            });

            timeline.play();
            this.lastIndex = index;
        }
    }
}