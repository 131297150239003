<template>
    <div v-bind:class="{'lightbox-container': true, 'd-none': !isOpen}">
      <img v-bind:src="'/static/' + image" v-if="image"/>
      <video ref="video" controls autoplay v-if="video">
        <source v-bind:src="'/static/'+video" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <div class="overlay" @click="close()"></div>
      <div class="close-btn" @click="close()">&#10005;</div>
    </div>
</template>

<script>
export default {
  name: 'InteraLightbox',
  components: {},
  props: {
    image: {
      type: String,
      default: null
    },
    video: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.$refs.video.pause();
    },
    reloadVideo() {
      if (this.$props.video) {
        this.$refs.video.load();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/variables.scss';

.lightbox-container {
  @include flex-center-child-items;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    z-index: 9997;
    opacity: 0.95;
  }

  .close-btn {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    margin: $spacing-sm;
    cursor: pointer;
    font-size: 2.5rem;
  }

  img {
    position: relative;
    z-index: 9998;
    max-width: 80%;
    max-height: 80%;
  }
  video {
    position: relative;
    z-index: 9998;
    width: 80%;
    height: 80%;
  }
}
</style>