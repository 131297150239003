import axios from 'axios';
import Request from '@/services/Request';

export default class EmailService {
    static send(email, phone, message, captcha) {
        return Request.request('POST', '/api/mail', {
            email: email,
            phone: phone,
            message: message,
            captcha: captcha
        });
    }
}