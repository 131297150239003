<script src="./index.js"/>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="bg-noise-pattern">
    <div class="wrapper">
      <div class="container">
        <div class="details-gallery-title">
          <div>{{ data.title }}</div>
          <div>{{ data.subTitle }}</div>
        </div>
        <div class="details-gallery-subtitle">{{ data.description }}</div>
        <div class="row">
          <div class="col-4 gallery-item"
               v-for="item in data.gallery"
               v-bind:style="{'background-image': 'url(/static/' + item + ')'}"
               @click="openLightbox(item)">
          </div>
        </div>
      </div>
    </div>
  </div>
  <InteraLightbox ref="lightbox" :image="image"/>
</template>

