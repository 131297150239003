import Button from "@/components/Common/Button";

export default {
    name: 'HomeProcessReview',
    components: {
        Button,
    },
    mounted() {

    }
}