import InteraLightbox from "@/components/Common/InteraLightbox/InteraLightbox";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { dragscroll } from 'vue-dragscroll'
import 'swiper/swiper.min.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';


export default {
    name: 'ServicePageCarousel',
    directives: {
        dragscroll
    },
    components: {
        InteraLightbox,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Swiper,
        SwiperSlide
    },
    props: ['data'],
    data() {
        return {
            winW: null,
            carousel: null,
            carouselMobile: null,
            swiper: null,
            image: null,
            video: null,
        }
    },
    mounted() {
        const slideName = decodeURI(location.hash.substring(1).toLowerCase());
        const selectedTab = this.getSlideIndexByName(slideName);

        this.swiper = this.$refs.swiper;
        this.carousel = this.$refs.carousel;
        this.carouselMobile = this.$refs.carouselMobile;

        if (this.carousel) this.carousel.slideTo(selectedTab);
        if (this.carouselMobile) this.carouselMobile.slideTo(selectedTab);

        window.addEventListener('resize', this.setDimensions);
        this.setDimensions();
    },
    methods: {
        setDimensions() {
            this.winW = document.getElementById("service-carousel").clientWidth - 50;
        },
        getSlideIndexByName(name) {
            let index = 0;
            this.$props.data.categories.forEach((item, itemIndex)=>{
                if (item.name.toLowerCase() === name) {
                    index = itemIndex;
                }
            });
            return index;
        },
        openLightbox(asset, type) {
            let lightbox = this.$refs.lightbox;
            this.image = null;
            this.video = null;
            if (type === 'video') {
                this.video = asset;
                lightbox.reloadVideo();
            } else {
                this.image = asset;
            }
            lightbox.open();
        }
    }
}