<template>
  <svg @mouseover="animate()" id="Layer_1" x="0px" y="0px"
       viewBox="-15 -20 253 328" style="enable-background:new 0 0 253 298;" xml:space="preserve">
    <g>
		<radialGradient id="SVGID_1_" cx="-769.9822" cy="1301.6455" r="1"
                    gradientTransform="matrix(187.4563 276.7813 276.7813 -187.4563 -215893.6562 457173.9062)"
                    gradientUnits="userSpaceOnUse">
		<stop offset="0" style="stop-color:#EB174F"/>
      <stop offset="1" style="stop-color:#611DF1"/>
	</radialGradient>
      <path ref="svgPath" shape-rendering="crispEdges" class="st0" d="M 233 153 c 11 59 -78 166 -144 145.5 S -20 208 0 140 S 10 22 74.5 0 S 216 88 233 155 z"/>
</g>
</svg>

</template>

<script>
import {gsap} from 'gsap';

export default {
  name: 'ElipseSvg',
  components: {},
  data() {
    return {
      isRunning: false,
      timeLineRepeat: null,
      timeLineMoveRight: null,
      timeLineMoveLeft: null,
      shapeMoveRight: null,
      shapeMoveLeft: null,
    }
  },
  mounted() {
    let vm = this;
    const svg = this.$refs.svgPath;

    this.shapeMoveRight = "M 233 153 c 11 59 -89 166 -144 145.5 S -3 215 65 160 S 10 22 74.5 0 S 216 88 233 155 z";
    this.shapeMoveLeft = "M 133 152 c -34 54 41 123 -21 124 S -3 215 17 153 S 54 39 137 23 S 164 94 134 152 z";
    const shapeInit = "M253,152.5c0,67.9-76.1,145.5-144,145.5S0,207.9,0,140S6.6,0,74.5,0S253,84.6,253,152.5z";

    this.timeLineMoveRight = gsap.timeline()
        .to(svg, .8, {
          attr: {d: this.shapeMoveRight}
        }, 0);

    this.timeLineMoveLeft = gsap.timeline()
        .to(svg, .8, {
          attr: {d: this.shapeMoveLeft}
        }, 0);

    this.timeLineMoveLeft.eventCallback("onComplete", function() {
      vm.isRunning = false;
    });

    this.timeLineMoveRight.eventCallback("onComplete", function() {
      vm.isRunning = false;
    });

    this.createRepeatTimeline(shapeInit)
  },
  methods: {
    createRepeatTimeline(shapeInit) {
      const svg = this.$refs.svgPath;
      const shape = "M 233 153 c -22 58 -76.1 145.5 -144 145.5 S 0 207.9 0 140 S 6.6 0 74.5 0 S 253 84.6 233 155 z";
      const shape2 = "M 233 153 c 11 59 -78 166 -144 145.5 S -20 208 0 140 S 10 22 74.5 0 S 216 88 233 155 z";
      const shape3 = "M 215 158 c 11 59 -78 151 -144 145.5 S 40 207 0 140 S 10 22 76 11 S 216 88 214 158 z";

      this.timeLineRepeat = gsap.timeline({repeat: -1})
          .from(svg, .8, {
            attr: {d: shapeInit}
          }, 0)
          .to(svg, .8, {
            attr: {d: shape2}
          }, 0)
          .to(svg, .8, {
            attr: {d: shape3},
          })
          .to(svg, .8, {
            attr: {d: shape}
          })
          .to(svg, .8, {
            attr: {d: shapeInit}
          });
    },
    animateRight() {
      this.timeLineRepeat.kill();
      this.createRepeatTimeline(this.shapeMoveRight);
      this.timeLineMoveLeft.pause();

      this.timeLineMoveRight.restart();
      this.timeLineMoveRight.play();
    },
    animateLeft() {
      this.timeLineRepeat.kill();
      this.createRepeatTimeline(this.shapeMoveLeft);
      this.timeLineMoveRight.pause();

      this.timeLineMoveLeft.restart();
      this.timeLineMoveLeft.play();
    },
    animate() {
      if (this.isRunning === true) {
        console.log('block');
        return;
      }

      this.isRunning = true;
      this.timeLineRepeat.restart();
      this.timeLineRepeat.play();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";


.st0 {
  fill: url(#SVGID_1_);
  fill-opacity: 0.3;
}
</style>