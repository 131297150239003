import InteraCarousel from "@/components/Common/InteraCarousel/InteraCarousel";
import InteraCarouselContainer from "@/components/Common/InteraCarousel/InteraCarouselContainer";

export default {
    name: 'HomeCarousel',
    components: {
        InteraCarousel,
        InteraCarouselContainer
    },
    data() {
        return {
            index: 0,
            slides: [
                {
                    title: "dsadas",
                },
                {
                    title: "dsadas dasdas da",
                },
                {
                    title: "dsadas dasdas da",
                },
                {
                    title: "dsadas dasdas da",
                },
                {
                    title: "dsadas",
                },
                {
                    title: "dsadas dasdas da",
                },
                {
                    title: "dsadas dasdas da",
                },
                {
                    title: "dsadas dasdas da",
                },
            ]
        }
    },
    mounted() {
        this.slider = this.$refs.slider;
    },
    methods: {
        slidePrev() {
            this.slider.slidePrev();
        },
        slideNext() {
            this.slider.slideNext();
        },
    }
}