import axios from 'axios';
import Request from '@/services/Request';

export default class PageService
{
    static getPageById(id) {
        return Request.request('GET', '/page/' + id + '?lang=en');
    }

    static getPageBySlug(slug) {
        return Request.request('GET', '/api/page/' + slug + '.json?lang=en');
    }

    static savePage(page, type) {
        page.type = type;

        let uri = '/api/page';
        if ('id' in page && page.id !== null) {
            uri = '/api/page/' + page.id;
        }

        return Request.request('POST', uri, {page: page});
    }

    static deletePage(id) {
        return Request.request('DELETE', '/api/page/' + id);
    }
}